import React from 'react'
import { Link } from 'gatsby'

const PrivacyPolicyPage = () => {
  return (
    <main className="privacyPolicyPage">
      <div className="page-section">
        <h1 className="privacy-policy-heading">AdhereTech Privacy Policy</h1>

        <div className="disclaimer">
          <h2>Updated: December 27, 2023</h2>

          <div className="privacy-policy-subsection">
            <h3>Introduction</h3>
            <p>
              AdhereTech, LLC., (“<strong>we</strong>”, or “<strong>us</strong>” or “<strong>Company</strong>”) takes
              the privacy and security of your personal information seriously and provides this Privacy Policy (“
              <strong>Policy</strong>”) to explain how we collect, use, share and protect the personal information you
              provide and that we collect on <Link to="/">www.adheretech.com</Link> (our “<strong>Site</strong>”),
              through our products and services, any related mobile applications that we may make available, and any
              other service that links to this Policy (collectively, the “<strong>Services</strong>”).
            </p>
          </div>

          <div className="privacy-policy-subsection">
            <h3>By using the services, you agree to the terms of this policy.</h3>
            <p>Please review this Policy carefully before using our Service.</p>
          </div>

          <div className="privacy-policy-subsection">
            <h3>Scope; Third Party Sites</h3>
            <p>
              This Policy applies only to information we collect at and through the Services. It does not apply to
              information we receive from third parties unless discussed herein. When we refer to “personal information”
              in this Policy, we mean information that can reasonably be used to identify you. Our Services may also
              contain links to third party sites that are not owned or controlled by AdhereTech. Please be aware that we
              are not responsible for the privacy practices of such other sites. We encourage you to be aware when you
              leave our Services and to read the privacy statements of each and every website that collects personal
              information.
            </p>
            <p>
              If you have entered into a separate agreement with us, that separate agreement shall control, and only
              those terms within this Privacy Policy that do not conflict with such separate agreement shall apply.
              Additionally, if we have received your Personal Information through a pharmacy or other healthcare
              provider subject to a Business Associate Agreement under The Health Insurance Portability and
              Accountability Act of 1996 (HIPAA), then we will treat your personal information in accordance with the
              Business Associate Agreement, and only the terms within this Privacy Policy that do not conflict with such
              Business Associate Agreement shall apply.
            </p>
          </div>
        </div>

        <div className="privacy-policy-section">
          <h2>1. Information We Collect</h2>

          <div className="privacy-policy-subsection">
            <h3>
              <u>Information You Provide to Us</u>
            </h3>

            <h4>Your Interaction with Us:</h4>
            <p>
              We collect personal information when you interact with us. The specific types of information we collect
              will depend on the forum in which you interact with us. For example, if you send us an email, we will
              collect your email address and the content of your email.
            </p>
            <p>
              If you inquire about our Services through our Site, we may collect your contact information and
              information about your organization.
            </p>

            <h4>Aidia System and Related Services.</h4>
            <p>
              We collect personal information through our Aidia Smart BottleSystem and related services (collectively,
              the “Aidia System”). This information includes:
            </p>
            <ul>
              <li>Your contact details such as your name, address, email address, and phone number</li>
              <li>Date of birth</li>
              <li>
                Information about your guardian or caregiver, including your guardian’s or caregiver’s phone number (to
                receive dose reminders)
              </li>
              <li>Medical condition(s) you are being treated for</li>
              <li>Your medication and medication dosing regimen</li>
              <li>Other prescription information</li>
              <li>Your preferences related to the Aidia System</li>
            </ul>
            <p>
              We collect this information from you directly, through your pharmacy, or from your caretaker/guardian.
              Please only provide information to us that you have permission to give. For example, if you provide
              personal information about another person to us, you must have that person’s consent to provide it to us.
            </p>
          </div>

          <div className="privacy-policy-subsection">
            <h3>
              <u>Information We Automatically Collect</u>
            </h3>

            <h4>General.</h4>
            <p>
              We may use a variety of technologies that automatically (or passively) collect certain non-personally
              identifiable information whenever you visit the Site or use certain parts of our Services (
              <strong>“Non-Personally Identifiable Information”</strong>). Non-Personally Identifiable Information may
              include the browser that you are using, the URL that referred you to our Site, and how and when you use
              the Services. We may use Non-Personally Identifiable Information for various reasons, such as providing
              and enhancing the Services for you and other users. In addition, we may collect your IP (internet
              protocol) address or other unique identifiers that identify the device from which you access the Site or
              Services. Your IP address is a number that is automatically assigned to your device and which our
              computers use to identify your device. This information does not identify you, but if we were to associate
              any Non-Personally Identifiable Information or information that identifies your device with your personal
              information, we will treat it as personal information.
            </p>

            <h4>Cookies.</h4>
            <p>
              A cookie is a data file placed on a computer or other device when it is used to access our Site or
              Services. Cookies may be used for many purposes, including, without limitation, remembering you and your
              preferences and tracking your visits to our web pages. Cookies work by assigning a number to the user that
              has no meaning outside of the assigning website. If you do not want information to be collected through
              the use of cookies, your browser allows you to deny or accept the use of cookies. Cookies can be disabled
              or controlled by setting a preference within your web browser or on your device. If you choose to disable
              cookies on your device, some features of our Services may not function properly or may not be able to
              customize the delivery of information to you. You should be aware that we cannot control the use of
              cookies (or the resulting information) by third-parties, and use of third party cookies is not covered by
              our Policy.
            </p>
            <p>At the present time our Services do not respond to “Do Not Track” signals or similar mechanisms.</p>

            <h4>Analytics.</h4>
            <p>
              We use third party analytics partners to collect certain Non-Personally Identifiable Information about how
              our Services are used. We and our analytics providers use cookies, web beacons, and other technologies to
              receive and store certain types of information whenever you interact with our Services through your
              computer or mobile device. By using the Services, you consent to the processing of data about you by our
              analytics partners in the manner and for the purposes set out this Policy. Currently, our analytics
              partners include, without limitation, Google Analytics. In order to make sure you understand how our
              analytics partners handle your non-personally identifiable information and how they interact with our
              Services, you should visit the pages linked below for more information, as AdhereTech has no ability to
              control or monitor our analytics partners’ data collection or data use practices with respect to such
              information. Google Analytics uses cookies to help us understand how visitors use our Service and to
              compile reports on website activity. For more information regarding Google Analytics please visit{' '}
              <a href="https://www.google.com/analytics/terms/us.html">
                https://www.google.com/analytics/terms/us.html
              </a>
              . For information on how to opt-out of Google Analytics, please visit{' '}
              <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a>.
            </p>

            <h4>Location Information.</h4>
            <p>
              We may collect approximate (but not specific) location information through your use of the Aidia System.
              We do not use this information for commercial purposes in any way.
            </p>

            <h4>Web Beacons.</h4>
            <p>
              Small graphic images or other web programming code called web beacons (also known as “1x1 GIFs” or “clear
              GIFs”) may be included in our Services. Web beacons are tiny graphics with a unique identifier, similar in
              function to cookies, and are used to track the online movements of web users. In contrast to cookies,
              which are stored in a user’s computer hard drive, web beacons are embedded invisibly on web pages and are
              about the size of the period at the end of this sentence. Web beacons or similar technologies help us
              better manage content on our Services by informing us what content is effective, count users of the
              Services, monitor how users navigate the Services, count how many e-mails we sent that were actually
              opened or to count how many particular articles or links were actually viewed. We do not tie the
              information gathered by web beacons to our customers’ personal information.
            </p>

            <h4>Embedded Scripts.</h4>
            <p>
              An embedded script is programming code that is designed to collect information about your interactions
              with the Site, such as the links you click on. We may use embedded scripts in providing the Services. If
              we do, the code is temporarily downloaded onto your device from our web server or a third party service
              provider, is active only while you are connected to the Site, and is deactivated or deleted thereafter.
            </p>

            <h4>Aidia System</h4>
            <p>
              We automatically collect information about your usage of the Aidia Smart Bottle System, such as your
              activity (for example, when the Aidia Smart Bottle was opened), information about your dose adherence (for
              example, when you forget a dose or take it late), and reminder preferences.
            </p>
          </div>
        </div>

        <div className="privacy-policy-section">
          <h2>2. How We Use Your Information</h2>

          <div className="privacy-policy-subsection">
            <p>
              In general, we collect personal information so that we can provide our Services, operate our business, and
              provide information that you request from us. Specifically we collect and use personal information for the
              following purposes:
            </p>
            <ul>
              <li>Create and administer your account.</li>
              <li>Provide, operate, improve, maintain, and protect our Services.</li>
              <li>Provide you with technical and other support.</li>
              <li>
                Send you services and company updates, marketing communication, and other information about AdhereTech
                and our Services, and products and services of third parties that we think you may be interested in.
              </li>
              <li>Conduct research and analysis, and monitor and analyze trends and usage.</li>
              <li>
                Enhance or improve user experience, our business, and our services, including the safety and security
                thereof.
              </li>
              <li>Personalize our Services to you.</li>
              <li>Communicate with you and respond to inquiries.</li>
              <li>
                Operate our business and perform any other function that we believe in good faith is necessary to
                protect the security or proper functioning of our services.
              </li>
              <li>
                As necessary to comply with any applicable law, regulation, subpoena, legal process, or governmental
                request.
              </li>
              <li>
                Enforce contracts and applicable Terms of Service, including investigation of potential violations
                thereof.
              </li>
              <li>Detect, prevent, or otherwise address fraud, security or technical issues.</li>
              <li>
                Protect against harm to the rights, property or safety of AdhereTech, our Services users, or the public
                as required or permitted by law.
              </li>
            </ul>

            <h4>The Aidia System.</h4>
            <p>
              If you use our Aidia System, in addition to the uses described above, we may use your personal
              information, to provide individualized or specialized Services to you (for example, we may alert a care
              partner if you have a streak of missed doses captured by the Aidia System), communicate with your
              pharmacy, and manage your account.
            </p>
          </div>
        </div>

        <div className="privacy-policy-section">
          <h2>3. How We Share Your Information</h2>

          <div className="privacy-policy-subsection">
            <h4>General.</h4>
            <p>
              We do not sell, share, rent or trade the information we have collected about you, including Personally
              Identifiable Information, other than as disclosed within this Privacy Policy. We do not use your
              Personally Identifiable Information for direct marketing purposes or share your Personally Identifiable
              Information with third parties for those third parties’ direct marketing purposes. We may share
              Non-Personally Identifiable Information, such as aggregated user statistics and log data, with third
              parties for industry analysis or for other business purposes.
            </p>
          </div>

          <div className="privacy-policy-subsection">
            <h4>The Aidia System</h4>
            <p>
              In addition to the other applicable sharing identified in this section, if you use the Aidia System, we
              share personal information with your pharmacy and/or healthcare service organization about your use of the
              Aidia Smart Bottle such as, for example, if you have a streak of missed doses captures by the Aidia
              System, or if you pause or discontinue taking a certain medication. We may also share such information
              with your designated health care provider, as well as for clinical and academic research purposes.
            </p>
          </div>

          <div className="privacy-policy-subsection">
            <h4>Service Providers</h4>
            <p>
              We use third parties to assist us with operating our business and providing our Services, such as our
              technology vendors that help us maintain our services and partners that assist us with our marketing and
              communication. In some cases, these service providers will have access to your personal information in
              order to provide services to us. We will enter into special contracts with such vendors strictly limiting
              the service providers’ ability to use and disclose personal information and requiring the use of certain
              security measures by the service providers.
            </p>
          </div>

          <div className="privacy-policy-subsection">
            <h4>Administrative and Legal Reasons.</h4>
            <p>
              We may share personal information with third party companies, organizations, governmental authorities, or
              individuals outside of AdhereTech if we have a good-faith belief that access, use, preservation or
              disclosure of the information is reasonably necessary to:
            </p>
            <ul>
              <li>Meet any applicable law, regulation, subpoena, legal process or governmental request;</li>
              <li>
                Enforce a contract, including but not limited to any applicable Terms of Service, including
                investigation of potential violations thereof;
              </li>
              <li>Detect, prevent, or otherwise address fraud, security or technical issues; or</li>
              <li>
                Protect against harm to the rights, property or safety of AdhereTech, our Services users, or the public
                as required or permitted by law.
              </li>
            </ul>
          </div>

          <div className="privacy-policy-subsection">
            <h4>Business Transfer.</h4>
            <p>
              We may, to the extent permitted by law, share personal information with our parent, subsidiaries and
              affiliates for internal, business related, marketing, and other lawful purposes. We also reserve the right
              to disclose and transfer personal information: (i) to a subsequent owner, co-owner or operator of the
              Services or our business; or (ii) in connection with a corporate merger, consolidation, restructuring, the
              sale of substantially all of our interests and/or assets or other corporate change, including, during the
              course of any due diligence process, all to the extent permitted by law. In such a case, unless otherwise
              directed by applicable law, your information would remain subject to the terms of the applicable Policy in
              effect at the time of such transfer.
            </p>
          </div>

          <div className="privacy-policy-subsection">
            <h4>As Directed By You and With Your Consent.</h4>
            <p>
              Except as otherwise provided in this Policy, we share personal information with companies, organizations
              or individuals outside of AdhereTech only at your direction or when we have your consent to do so.
            </p>
          </div>

          <div className="privacy-policy-subsection">
            <h4>Aggregate Information.</h4>
            <p>
              We may de-identify or aggregate personal information so that you are not identified as an individual, and
              use and provide that information to third parties without restriction. We may also provide aggregate usage
              information to third parties (or allow third parties to collect that information from you), who may use
              such information to understand how often and in what ways people use our Services. However, we never
              disclose aggregate usage or de-identified information to a third party (or allow a third party to collect
              such information) in a manner that would identify you as an individual person.
            </p>
          </div>
        </div>

        <div className="privacy-policy-section">
          <h2>4. Account Changes; Choices; Cancellation.</h2>

          <div className="privacy-policy-subsection">
            <h4>The Aidia System.</h4>
            <p>
              If you use our Aidia System, you may contact us at{' '}
              <a href="mailto:support@adheretech.com">support@adheretech.com</a> to request that we correct, delete, or
              rectify your personal information that is stored on our systems. Changes will be made as soon as
              practicable. You may also contact your pharmacy or health care provider to request changes to your
              personal information. We will make changes to such information on our systems if notified by pharmacy or
              health care provider to do so.
            </p>
          </div>

          <div className="privacy-policy-subsection">
            <h4>Retention of Information.</h4>
            <p>
              We retain personal information in accordance with applicable laws. The length of time we keep personal
              information depends on the type of information and whether we have an on-going business or legal need to
              retain it (for example, if you have an account with us, or to comply with applicable legal, tax or
              accounting requirements).
            </p>
          </div>

          <div className="privacy-policy-subsection">
            <h4>Choices.</h4>
            <p>
              You may contact us at <a href="mailto:support@adheretech.com">support@adheretech.com</a> to request that
              we correct, delete, or rectify your personal information that is stored on our systems. Changes will be
              made as soon as practicable. Please note that in some cases, we may not be able to fulfill your request,
              or may be prohibited from correcting, deleting, or rectifying some or all of your personal information. We
              will let you know in such instances.
            </p>
            <p>
              If you opt-in, we may send you promotional and other SMS text messages or pre-recorded voice messages. You
              have the right to opt out of these messages at any time by texting STOP or a similar message in response
              to a message from AdhereTech or the Aidia System. You may also otherwise contact us to opt out of these
              messages. No further texts will be sent unless and until you re-enroll. Text HELP for help.
            </p>
            <p>
              Additionally, you can always choose to stop receiving newsletters or other communications from us by
              clicking the “unsubscribe” link at the bottom of any AdhereTech marketing message.
            </p>
          </div>
        </div>

        <div className="privacy-policy-section">
          <h2>5. Children</h2>
          <p>
            Our Services are not directed to children under 13 years old and we do not knowingly collect personal
            information from children under the age of 13.
          </p>
        </div>

        <div className="privacy-policy-section">
          <h2>6. How We Protect Your Information</h2>
          <p>
            We employ industry standard and commercially reasonable security practices and procedures such as
            encryption, firewalls and SSL (Secure Socket Layers). We also implement security measures required by law.
            However, as effective as such technology and efforts may be, no security system is infallible and impervious
            from attack or hacking. Therefore, we cannot guarantee the security of our databases, nor can we guarantee
            that personal information won’t be accessed, disclosed, altered, or destroyed by breach of any of our
            physical, technical, or managerial safeguards.
          </p>
        </div>

        <div className="privacy-policy-section">
          <h2>7. Changes To This Policy</h2>
          <p>
            We may update this Policy from time to time. Any changes will be effective immediately upon the posting of
            the revised Policy. We encourage you to periodically review this Policy for the latest information on our
            privacy practices.
          </p>
        </div>

        <div className="privacy-policy-section">
          <h2>8. Your California Privacy Rights</h2>
          <p>
            California’s “Shine the Light” law, California Civil Code § 1798.83, requires that certain businesses
            respond to requests from their California customers (those with whom we have an established business
            relationship) asking about the business’ practices related to disclosing Personally Identifiable Information
            to third parties for the third parties’ direct marketing purposes. At this time, we do not provide
            Personally Identifiable Information to third parties for their direct marketing purposes.
          </p>
        </div>

        <div className="privacy-policy-section">
          <h2>9. Nevada Residents</h2>
          <p>
            Nevada consumers can opt out of the sale of their personal information. We do not sell personal information
            today, and we do not have plans to sell personal information in the future. However, Nevada customers have
            the legal right to opt out of the sale of their personal information, even if their information is not
            currently being sold. You may opt out by emailing a request to{' '}
            <a href="mailto:support@adheretech.com">support@adheretech.com</a>. In your request, please specify that you
            wish to “Opt-out of Sale of Personal Information in Nevada.” Please allow 60 days for a response.
          </p>
        </div>

        <div className="privacy-policy-section">
          <h2>10. Notice To Users Outside Of The United States</h2>
          <p>
            If you are located outside of the United States, please note that personal information we collect about you
            may be transferred to, processed and stored in the United States, unless otherwise noted. The data
            protection laws in the United States may differ from those of the country or jurisdiction in which you are
            located, and your personal information may be subject to access requests from governments, courts, or law
            enforcement in the United States according to laws of the United States. By using the Service or providing
            us with any information, you expressly and unambiguously consent to the transfer, processing and storage of
            your personal information in the United States.
          </p>
        </div>

        <div className="privacy-policy-section">
          <h2>11. Contact Us</h2>
          <p>
            If you have any comments, questions, concerns, or suggestions about this Policy, or about our privacy
            practices in general, please email us at <a href="mailto:support@adheretech.com">support@adheretech.com</a>.
          </p>
        </div>
      </div>
    </main>
  )
}

export default PrivacyPolicyPage
